<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :status-filter="statusFilter"
        :suppliers-filter="suppliersFilter"
        :date-filter="dateFilter"
        :type-filter="typeFilter"
        @updateStatusFilter="updateStatusFilter($event)"
        @updateSuppliersFilter="updateSuppliersFilter($event)"
        @updateDateFilter="updateDateFilter($event)"
        @updateTypeFilter="updateTypeFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        :search-type="searchType"
        :search-options="searchOptions"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal 3rd store -->
    <!-- End: Modal 3rd store -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },
  data() {
    return {
      searchType: 0,
      searchOptions: [
        { type: "product_name", label: "Theo tên hàng" },
        { type: "MPN", label: "Theo MPN" },
        { type: "SKU", label: "Theo SKU" },
        { type: "global_tracking", label: "Theo tracking number" },
        { type: "code", label: "Theo mã PO" }
      ],
      currentPage: 1,
      itemPerPage: 50,
      searchKey: null,
      statusFilter: null,
      suppliersFilter: null,
      dateFilter: {
        value: null,
        type: 1
      },
      typeFilter: null
    };
  },
  watch: {
    "$route.query"(val) {
      this.getPurchaseOrders(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getPurchaseOrders(route.query);
  },
  methods: {
    async pushRouter() {
      await this.$router.push({
        name: "transaction_goods-receipts",
        query: {
          supplier_id:
            this.suppliersFilter && this.suppliersFilter.length > 0
              ? this.suppliersFilter
              : undefined,
          // type:
          //   this.typeFilter && this.typeFilter !== "all"
          //     ? this.typeFilter
          //     : undefined,
          status:
            this.statusFilter !== undefined &&
            this.statusFilter !== null &&
            this.statusFilter !== "all"
              ? this.statusFilter
              : undefined,
          date_type:
            this.dateFilter.value &&
            this.dateFilter.value[0] &&
            this.dateFilter.type
              ? this.dateFilter.type
              : undefined,
          date_from:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : undefined,
          date_to:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getPurchaseOrders(query) {
      if (query.page) {
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set filtered status
        this.statusFilter =
          query.status !== undefined ? parseInt(query.status) : "all";
        // Set filtered date
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };
        // Set filtered type
        // this.typeFilter = query.type ? parseInt(query.type) : "all";
        // Set search key
        this.searchKey = query.search || null;
        // Set filtered suppliers
        this.suppliersFilter =
          typeof query.supplier_id === "string"
            ? [parseInt(query.supplier_id)]
            : typeof query.supplier_id === "object"
            ? query.supplier_id.map(item => parseInt(item))
            : [];

        // Get products
        await this.$store.dispatch("PURCHASE_ORDER/getPurchaseOrders", {
          filter: {
            supplier_id: this.suppliersFilter,
            status:
              this.statusFilter !== undefined &&
              this.statusFilter !== null &&
              this.statusFilter !== "all"
                ? [this.statusFilter]
                : null,
            fromDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[0]
                : null,
            toDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[1]
                : null
          },
          search: this.searchKey,
          search_key: this.searchOptions[this.searchType].type,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateStatusFilter(val) {
      this.statusFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSuppliersFilter(val) {
      this.suppliersFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateDateFilter(val) {
      this.dateFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateTypeFilter(val) {
      this.typeFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    getIndexOfSearchOption(option) {
      const types = this.searchOptions.map(item => item.type);

      return types.indexOf(option);
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      if (val.option) {
        this.searchType = this.getIndexOfSearchOption(val.option);
      }
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
