<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="purchaseOrderStatusRequest.value === 'loading-getPurchaseOrders'"
    loading-text="Đang tải dữ liệu"
    :items="purchaseOrders"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <!-- Start: Body -->
    <template v-slot:body="{ items }">
      <tbody v-if="items.length > 0">
        <!-- Start: Total -->
        <tr class="yellow lighten-5">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="font-weight-bold">
            {{ purchaseOrderListTotals.total_value | formatCurrency }}
          </td>
          <td></td>
        </tr>
        <!-- Start: Total -->
        <!-- Start: Items -->
        <tr v-for="item in items" :key="item.id" @click="viewDetail(item)">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="copied-label"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="copyToClipboard(item.code)"
                >
                  {{ item.code }}
                </div>
              </template>
              <span>Sao chép</span>
            </v-tooltip>
          </td>
          <td>
            <template v-if="item.options.length > 0">
              <ul
                class="my-2"
                v-for="(option, index) in item.options"
                :key="index"
              >
                <li class="">
                  {{ `${option.option.product_name} - ${option.option.SKU}` }}
                </li>
              </ul>
            </template>
            <div v-else>Chưa có sản phẩm nào.</div>
          </td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.created_by_user.name }}</td>
          <td>
            {{ item.supplier ? item.supplier.name : "N/A" }} -
            {{ item.supplier ? item.supplier.code : "N/A" }}
          </td>
          <td>{{ calculatePurchaseOrderValue(item) | formatCurrency }}</td>
          <td class="text-center">
            <v-chip
              v-if="item.status === -2"
              class="font-weight-bold"
              color="teal"
              small
              outlined
            >
              Kết thúc sớm
            </v-chip>
            <v-chip
              v-else
              class="font-weight-bold"
              :color="
                item.status === -1
                  ? 'red accent-2'
                  : statusList[item.status].color
              "
              small
              outlined
            >
              {{ item.status === -1 ? "Bị hủy" : statusList[item.status].text }}
            </v-chip>
          </td>
        </tr>
        <!-- End: Items -->
      </tbody>

      <tbody v-else>
        <tr class="v-data-table__empty-wrapper">
          <td colspan="7">Không có dữ liệu</td>
        </tr>
      </tbody>
    </template>
    <!-- End: Body -->
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Mã đơn",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Sản phẩm",
          align: "start",
          sortable: false,
          value: "options"
        },
        {
          text: "Thời gian tạo",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Người tạo",
          align: "start",
          sortable: false,
          value: "created_by_user"
        },
        {
          text: "Nhà cung cấp",
          align: "start",
          sortable: false,
          value: "supplier"
        },
        {
          text: "Giá trị đơn nhập",
          align: "start",
          sortable: false,
          value: "value"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "status"
        }
      ],
      statusList: [
        { value: 0, text: "Nháp", color: "grey" },
        { value: 1, text: "Chờ duyệt", color: "purple" },
        { value: 2, text: "Đã duyệt", color: "blue" },
        { value: 3, text: "Đang nhập", color: "amber" },
        { value: 4, text: "Thành công", color: "green" }
      ]
    };
  },
  computed: {
    purchaseOrderListTotals() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrderListTotals"];
    },
    purchaseOrders() {
      return this.$store.getters["PURCHASE_ORDER/purchaseOrders"];
    },
    purchaseOrderStatusRequest() {
      return this.$store.getters["PURCHASE_ORDER/statusRequest"];
    }
  },
  methods: {
    calculatePurchaseOrderOptionRebate(option) {
      if (option.rebates.length > 0) {
        let total = 0;

        option.rebates.forEach(item => {
          if (item.value) {
            total += item.value;
          }
        });

        return total;
      } else {
        return 0;
      }
    },

    calculatePurchaseOrderValue(purchaseOrder) {
      let total = 0;

      purchaseOrder.options.forEach(option => {
        if (option.import_price && option.import_qty) {
          total +=
            (parseInt(option.import_price) -
              this.calculatePurchaseOrderOptionRebate(option)) *
            parseInt(option.import_qty);
        }
      });

      return total;
    },

    getIndexOfPurchaseOrder(val) {
      const arr = this.purchaseOrders.map(item => item.id);

      return arr.indexOf(val);
    },

    async viewDetail(item) {
      await this.$router.push({
        name: "transaction_goods-receipts_purchase-orders-detail",
        params: {
          purchaseOrderId: item.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
