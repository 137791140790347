var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.purchaseOrderStatusRequest.value === 'loading-getPurchaseOrders',"loading-text":"Đang tải dữ liệu","items":_vm.purchaseOrders,"item-key":"id"},on:{"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length > 0)?_c('tbody',[_c('tr',{staticClass:"yellow lighten-5"},[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.purchaseOrderListTotals.total_value))+" ")]),_c('td')]),_vm._l((items),function(item){return _c('tr',{key:item.id,on:{"click":function($event){return _vm.viewDetail(item)}}},[_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1),_c('td',[(item.options.length > 0)?_vm._l((item.options),function(option,index){return _c('ul',{key:index,staticClass:"my-2"},[_c('li',{},[_vm._v(" "+_vm._s(((option.option.product_name) + " - " + (option.option.SKU)))+" ")])])}):_c('div',[_vm._v("Chưa có sản phẩm nào.")])],2),_c('td',[_vm._v(_vm._s(item.created_at))]),_c('td',[_vm._v(_vm._s(item.created_by_user.name))]),_c('td',[_vm._v(" "+_vm._s(item.supplier ? item.supplier.name : "N/A")+" - "+_vm._s(item.supplier ? item.supplier.code : "N/A")+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.calculatePurchaseOrderValue(item))))]),_c('td',{staticClass:"text-center"},[(item.status === -2)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"teal","small":"","outlined":""}},[_vm._v(" Kết thúc sớm ")]):_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.status === -1
                ? 'red accent-2'
                : _vm.statusList[item.status].color,"small":"","outlined":""}},[_vm._v(" "+_vm._s(item.status === -1 ? "Bị hủy" : _vm.statusList[item.status].text)+" ")])],1)])})],2):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":"7"}},[_vm._v("Không có dữ liệu")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }